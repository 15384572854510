import { Col, Row } from "react-bootstrap";
import RestDashNav from "../../Components/RestDashNav/RestDashNav";
import "./dashBoard.css";
import dollar from "../../assets/RestDash/dashBoard/dollar.png";
import tokens from "../../assets/RestDash/dashBoard/tokens.svg";
import charts from "../../assets/RestDash/dashBoard/charts.png";
import food_1 from "../../assets/RestDash/dashBoard/food_1.png";
import person_1 from "../../assets/RestDash/dashBoard/person_1.png";
import DashBoardFooter from "../../Components/DashBoardFooter/DashBoardFooter";
import { useEffect, useState } from "react";
import {
  getFavItems,
  getRecentOrders,
  getRecentUsers,
  getRestSlaes,
  getRestTokens,
} from "../../utilies/api";
import { useNavigate } from "react-router-dom";

const DashBoard = () => {
  const navigate = useNavigate();
  const user_id = localStorage.getItem("user_id");
  const [recentOrders, setRecentOrders] = useState([]);
  const [recentUsers, setRecentUsers] = useState([]);
  const [favItems, setFavItems] = useState([]);
  const [totalSales, setTotalSales] = useState("");
  const [totalTokens, setTotalTokens] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const recentOrders = async () => {
      try {
        const response = await getRecentOrders(user_id);
        setRecentOrders(response.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    const recentUsers = async () => {
      try {
        const response = await getRecentUsers(user_id);
        setRecentUsers(response.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    const totalSalesFun = async () => {
      try {
        const response = await getRestSlaes(user_id);
        setTotalSales(response.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    const totalTokensFun = async () => {
      try {
        const response = await getRestTokens(user_id);
        setTotalTokens(response.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    const favItems = async () => {
      try {
        const response = await getFavItems(user_id);
        setFavItems(response.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    totalSalesFun();
    totalTokensFun();
    recentOrders();
    recentUsers();
    favItems();
  }, [user_id]);

  return (
    <>
      <RestDashNav
        title={"Dashboard"}
        subTitle={"Summary of your Restaurant Dashboard"}
        redirectPath={"/login"}
      />
      <div className="my-orders dashboard">
        {isLoading ? (
          <div className="spinner">
            <div className="loader"></div>
          </div>
        ) : (
          <div>
            <Row>
              <Col xs={12} md={12} lg={12} xl={5}>
                <Row>
                  <Col xs={12} md={6} lg={6} xl={6} className="mb-3">
                    <div className="total-sales">
                      <span
                        onClick={() => navigate("/revenewChart")}
                        className="total-btn"
                      >
                        View Details
                      </span>
                      <img src={dollar} alt="dollar" className="img-price" />
                      <div>
                        <h3>
                          {totalSales ? parseInt(totalSales).toFixed(2) : 0} JOD
                        </h3>
                        <p>Total sales</p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={6} xl={6} className="mb-3">
                    <div className="total-sales total-tokens">
                      <span
                        className="total-btn"
                        onClick={() => navigate("/tokens-chart")}
                      >
                        View Details
                      </span>
                      <img
                        src={tokens}
                        alt="tokens"
                        className="img-price token-price"
                      />
                      <div>
                        <h3>{(totalTokens / 1000).toFixed(0)} K</h3>
                        <p>Total Tokens</p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} md={12} lg={12} xl={12} className="mb-3">
                    <div className="charts-sales">
                      <p
                        onClick={() => navigate("/revenewChart")}
                        className="btn-more-sales"
                      >
                        view all
                      </p>
                      <div className="text-center mt-5">
                        <img
                          src={dollar}
                          alt="dollar"
                          className="img-price mb-2"
                        />
                        <h3 className="mb-2">0%</h3>
                        <p>sales</p>
                      </div>
                      <div className="charts-img">
                        <img className="w-100" src={charts} alt="charts" />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={12} lg={12} xl={7}>
                <div
                  className="table-container table-responsive mb-3 text-nowrap text-capitalize"
                  style={{ height: "414px" }}
                >
                  <div className="fav-heading">
                    <h2>Recent order</h2>
                    <p
                      onClick={() => {
                        navigate("/myOrders");
                      }}
                    >
                      View all
                    </p>
                  </div>
                  <table className="table table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">Order Number</th>
                        <th scope="col">Customer Details </th>
                        <th scope="col">Items </th>
                        <th scope="col">Total Amount</th>
                        <th scope="col">Status</th>
                        <th scope="col">Order Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {recentOrders.length > 0 ? (
                        recentOrders
                          .slice(0, 10)
                          .reverse()
                          .map((order) => {
                            return (
                              <tr key={order?.id}>
                                <th
                                  className="text-center"
                                  scope="row"
                                  onClick={() => {
                                    navigate(`/orderDetails/${order?.id}`);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    color: "#415FAC",
                                    textDecoration: "underline",
                                    textAlign: "center",
                                  }}
                                >
                                  #{order?.id}
                                </th>
                                <td>
                                  <p className="fw-bold">{order?.name}</p>
                                  <p className="fw-bold">{order?.address}</p>
                                  <p className="fw-bold">
                                    {order?.customer?.phone}
                                  </p>
                                </td>
                                <td>
                                  <div className="gredients">
                                    <p>
                                      {order.items.length > 0 ? (
                                        order.items.slice(0, 1).map((item) => (
                                          <div key={item?.id}>
                                            <p className="fw-bold">
                                              {item?.qty}x {item?.english_name}
                                            </p>
                                            <span
                                              onClick={() =>
                                                navigate(
                                                  `/orderDetails/${order?.id}`
                                                )
                                              }
                                              className="fw-bold"
                                              style={{
                                                color: "rgba(0, 0, 0, 0.5)",
                                                cursor: "pointer",
                                              }}
                                            >
                                              ... More
                                            </span>
                                          </div>
                                        ))
                                      ) : (
                                        <span className="text-danger fw-bold">
                                          No Items
                                        </span>
                                      )}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <span className="total-amount">
                                    {order?.total} JOD
                                  </span>
                                </td>
                                <td>
                                  <span
                                    className="status px-2"
                                    style={
                                      order?.status === "completed" ||
                                      order?.status === "received"
                                        ? { backgroundColor: "#129D54" }
                                        : order?.status === "in progress"
                                        ? {
                                            backgroundColor: "#F4A223",
                                          }
                                        : { backgroundColor: "#FF3434" }
                                    }
                                  >
                                    {order?.status}
                                  </span>
                                </td>
                                <td>
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      textTransform: "capitalize",
                                      textAlign: "center",
                                    }}
                                  >
                                    {order?.orderType}
                                  </p>
                                </td>
                              </tr>
                            );
                          })
                      ) : (
                        <tr>
                          <td colSpan={8}>
                            <p className="empty-users">No Orders Found</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                    {/* <ModalComp show={show} handleClose={handleClose} /> */}
                  </table>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={6} xl={5} className="mb-3">
                <div className="fav-items">
                  <div className="fav-heading">
                    <h2>Favourite Items</h2>
                    {/* <p>View all</p> */}
                  </div>
                  {favItems.length > 0 ? (
                    favItems?.slice(0, 6).map((item) => {
                      return (
                        <div
                          className="fav-item mb-3 text-nowrap text-capitalize"
                          key={item?.id}
                        >
                          <div className="fav-item-img">
                            <img src={food_1} alt="food" />
                            <span className="px-2">{item?.english_name}</span>
                          </div>
                          <div
                            style={{ color: "rgba(217, 217, 217, 1)" }}
                            className="fav-item-info"
                          >
                            <span className="px-1">
                              {item?.percentage.toFixed(2)}%
                            </span>
                            <span>({item?.qty})</span>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="empty-users">No Favourite Items Found</p>
                  )}
                </div>
              </Col>
              <Col xs={12} md={12} lg={6} xl={7}>
                <div className="fav-items mb-5 text-nowrap text-capitalize">
                  <div className="fav-heading">
                    <h2>Recent Users</h2>
                    <p onClick={() => navigate("/rolesAndusers")}>View all</p>
                  </div>
                  {recentUsers?.length > 0 ? (
                    recentUsers?.slice(0, 6).map((user) => {
                      return (
                        <div className="fav-item mb-3" key={user?.id}>
                          <div className="fav-item-img">
                            <img src={person_1} alt="food" />
                            <span className="px-2">{user?.name}</span>
                          </div>
                          <div
                            style={{ color: "#000" }}
                            className="fav-item-info"
                          >
                            <span style={{ textTransform: "capitalize" }}>
                              {user?.level}
                            </span>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="empty-users">No Recent Users Found</p>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        )}

        <DashBoardFooter />
      </div>
    </>
  );
};

export default DashBoard;
