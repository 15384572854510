import { Button, Col, Modal, Row } from "react-bootstrap";
import RestDashNav from "../RestDashNav/RestDashNav";
import "./myOrders.css";
import { useNavigate } from "react-router-dom";
import PaginationComponent from "../PaginationComponent/PaginationComponent";
import DashBoardFooter from "../DashBoardFooter/DashBoardFooter";
import { useEffect, useState } from "react";
import edit_icon from "../../assets/RestDash/menu/edit_icon.png";
import {
  GetRestaurantOrdersDetails,
  updateRestaurantOrderStatus,
} from "../../utilies/api";

// get date and time function
export function getDateTime(stringDate) {
  const dateString = stringDate;
  const dateObject = new Date(dateString);

  // Extracting date components
  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() + 1; // Month is zero-indexed, so adding 1
  const day = dateObject.getDate();

  // Extracting time components
  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const seconds = dateObject.getSeconds();

  return `${year}-${month}-${day}  ${hours}:${minutes}:${seconds}`;
}
const MyOrders = () => {
  const rest_id = localStorage.getItem("user_id");
  const [show, setShow] = useState(false);
  const [showId, setShowId] = useState(0);
  const handleClose = () => setShow(false);
  const handleShow = (idx) => {
    setShow(true);
    setShowId(idx);
  };
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [orderId, setOrderId] = useState("");
  const [cstName, setCstName] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [orderMethod, setOrderMethod] = useState("");
  const [orderType, setOrderType] = useState("");
  const [orderDate, setOrderDate] = useState("");
  const [changeStatus, setChangeStatus] = useState();
  const [isLoading, setIsLoading] = useState(true);
  // pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [totalItems, setTotalItems] = useState();

  const getOrders = async (
    id,
    name,
    status,
    orderMethod,
    orderDate,
    orderType,
    restaurant_id,
    page
  ) => {
    try {
      const result = await GetRestaurantOrdersDetails(
        id,
        status,
        name,
        orderMethod,
        orderDate,
        orderType,
        restaurant_id,
        page
      );
      setOrders(result.data.reverse());
      setCurrentPage(result.pagination.page);
      setTotalPages(result.pagination.totalPages);
      setHasNextPage(result.pagination.hasNextPage);
      setHasPreviousPage(result.pagination.hasPreviousPage);
      setTotalItems(result.pagination.totalItems);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const changeOrderStatusFun = async (id, status) => {
    try {
      const formData = {
        id,
        status,
      };
      await updateRestaurantOrderStatus(formData);
      await getOrders(
        orderId,
        cstName,
        orderStatus,
        orderMethod,
        orderDate,
        orderType,
        rest_id,
        currentPage
      );
      handleClose();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handleNextPage = () => {
    if (hasNextPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (hasPreviousPage) {
      setCurrentPage(currentPage - 1);
    }
  };
  useEffect(() => {
    getOrders(
      orderId,
      cstName,
      orderStatus,
      orderMethod,
      orderDate,
      orderType,
      rest_id,
      currentPage
    );
  }, [
    orderId,
    cstName,
    orderStatus,
    orderMethod,
    orderDate,
    orderType,
    rest_id,
    currentPage,
  ]);
  return (
    <>
      <RestDashNav
        title={"My Orders"}
        subTitle={totalItems > 0 ? `${totalItems} orders` : "No Orders"}
        redirectPath={"/login"}
      />
      <div className="my-orders">
        <h1>Search & Filter</h1>
        <Row>
          <Col xs={12} md={3} lg={4}>
            <input
              type="number"
              min={1}
              className="form-control"
              placeholder="Order Number"
              value={orderId}
              onChange={(e) => setOrderId(e.target.value)}
            />
          </Col>
          <Col xs={12} md={3} lg={4}>
            <input
              type="text"
              className="form-control"
              placeholder="Customer Name"
              value={cstName}
              onChange={(e) => setCstName(e.target.value)}
            />
          </Col>
          <Col xs={12} md={3} lg={4}>
            <select
              className="form-select"
              defaultValue={"selected"}
              onChange={(e) => setOrderStatus(e.target.value)}
            >
              <option value="selected" disabled>
                Status
              </option>
              <option value="">All</option>
              <option value="in progress">inProgress</option>
              <option value="completed">completed</option>
              <option value="received">received</option>
              <option value="cancelled">cancelled</option>
            </select>
          </Col>
          <Col xs={12} md={3} lg={4}>
            <select
              className="form-select"
              defaultValue={"selected"}
              onChange={(e) => setOrderMethod(e.target.value)}
            >
              <option value="selected" disabled>
                Payment Method
              </option>
              <option value="">All</option>
              <option value="cash">cash</option>
              <option value="visa">visa</option>
            </select>
          </Col>
          <Col xs={12} md={3} lg={4}>
            <select
              className="form-select"
              defaultValue={"selected"}
              onChange={(e) => setOrderType(e.target.value)}
            >
              <option value="selected" disabled>
                Order Type
              </option>
              <option value="">All</option>
              <option value="delivery">delivery</option>
              <option value="takeaway">takeaway</option>
            </select>
          </Col>
          <Col xs={12} md={3} lg={4}>
            <input
              type="date"
              className="form-control"
              onChange={(e) => setOrderDate(e.target.value)}
            />
          </Col>
        </Row>
        {isLoading ? (
          <div className="spinner">
            <div className="loader"></div>
          </div>
        ) : (
          <Row>
            <Col lg={12}>
              <div
                className="table-container table-responsive text-nowrap text-capitalize"
                style={{
                  height: "60vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <table
                  className="table table-borderless"
                  style={{ textWrap: "no-wrap  !important" }}
                >
                  <thead>
                    <tr>
                      <th scope="col">Order Number</th>
                      <th scope="col">Customer Details </th>
                      <th scope="col">Items </th>
                      <th scope="col">Total Amount</th>
                      <th scope="col">Payment Method</th>
                      <th scope="col">Order Date</th>
                      <th scope="col">Status</th>
                      <th scope="col">Order Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.length > 0 ? (
                      orders.map((order, idx) => {
                        return (
                          <tr key={order?.id}>
                            <th
                              scope="row"
                              onClick={() => {
                                navigate(`/orderDetails/${order?.id}`);
                              }}
                              style={{
                                cursor: "pointer",
                                color: "#415FAC",
                                textDecoration: "underline",
                                textAlign: "center",
                              }}
                            >
                              #{order?.id}
                            </th>
                            <td>
                              <p className="fw-bold">{order?.name}</p>
                              <p className="fw-bold">{order?.address}</p>
                              <p className="fw-bold">
                                {order?.customer?.phone}
                              </p>
                            </td>
                            <td>
                              <div className="gredients">
                                <p className="fw-bold">
                                  {order.items.length > 0 ? (
                                    order.items.slice(0, 1).map((item) => (
                                      <>
                                        <p>
                                          {item?.qty}x {item?.english_name}
                                        </p>
                                        <span
                                          onClick={() =>
                                            navigate(
                                              `/orderDetails/${order?.id}`
                                            )
                                          }
                                          style={{
                                            color: "rgba(0, 0, 0, 0.5)",
                                            cursor: "pointer",
                                          }}
                                        >
                                          ... More
                                        </span>
                                      </>
                                    ))
                                  ) : (
                                    <span className="text-danger fw-bold">
                                      No Items
                                    </span>
                                  )}
                                </p>
                              </div>
                            </td>
                            <td>
                              <span className="total-amount fw-bold">
                                {order?.total} JOD
                              </span>
                            </td>
                            <td className="fw-bold">{order?.paymentMethod}</td>
                            <td className="fw-bold">
                              {getDateTime(order?.createdAt)}
                            </td>
                            <td>
                              <div className="d-flex align-items-center gap-2">
                                <span
                                  className="status px-2"
                                  style={
                                    order?.status === "completed" ||
                                    order?.status === "received"
                                      ? { backgroundColor: "#129D54" }
                                      : order?.status === "in progress"
                                      ? {
                                          backgroundColor: "#F4A223",
                                        }
                                      : { backgroundColor: "#FF3434" }
                                  }
                                >
                                  {order?.status}
                                </span>
                                <img
                                  onClick={() => handleShow(idx)}
                                  src={edit_icon}
                                  alt="edit_icon"
                                />
                                <Modal
                                  centered
                                  show={showId === idx && show}
                                  onHide={handleClose}
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title>
                                      Update Order Status #{order?.id}
                                    </Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    <label
                                      htmlFor="exampleFormControlInput1"
                                      className="form-label"
                                    >
                                      Change Order Status
                                    </label>
                                    <select
                                      className="form-select"
                                      aria-label="Default select example"
                                      defaultValue={order?.status}
                                      value={changeStatus}
                                      onChange={(e) =>
                                        setChangeStatus(e.target.value)
                                      }
                                    >
                                      <option disabled>
                                        select order status
                                      </option>
                                      <option value="received">received</option>
                                      <option value="completed">
                                        Completed
                                      </option>
                                      <option value="in progress">
                                        in progress
                                      </option>
                                      <option value="cancelled">
                                        cancelled
                                      </option>
                                    </select>
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <Button
                                      variant="secondary"
                                      onClick={handleClose}
                                    >
                                      Close
                                    </Button>
                                    <Button
                                      variant="primary"
                                      onClick={() =>
                                        changeOrderStatusFun(
                                          order?.id,
                                          changeStatus
                                        )
                                      }
                                    >
                                      Update Status
                                    </Button>
                                  </Modal.Footer>
                                </Modal>
                              </div>
                            </td>
                            <td>
                              <p
                                style={{
                                  fontWeight: "bold",
                                  textTransform: "capitalize",
                                  textAlign: "center",
                                }}
                              >
                                {order?.orderType}
                              </p>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={8}>
                          <p className="empty-users">No Orders Found</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Col>
            <PaginationComponent
              currentPage={currentPage}
              totalPages={totalPages}
              handleNextPage={handleNextPage}
              handlePrevPage={handlePrevPage}
              hasNextPage={hasNextPage}
              hasPreviousPage={hasPreviousPage}
            />
          </Row>
        )}
        <DashBoardFooter />
      </div>
    </>
  );
};

export default MyOrders;
