import { Col, Container, Row } from "react-bootstrap";
import "./login.css";
import logo from "../../assets/login-audio.png";
import mainLogo from "../../assets/mainLogo.svg";
import login_img from "../../assets/forms/login-img.png";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { userLogin } from "../../utilies/api";
import { notifyError, notifySuccess } from "../ContactUs/ContactUs";
import { ToastContainer } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import icons
import { jwtDecode } from "jwt-decode";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isRestaurant, setIsRestaurant] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State for showing password

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await userLogin({
        email,
        password,
        isRestaurant,
      });

      notifySuccess(response.data.message);
      const decodedToken = jwtDecode(response.data.token);
      console.log(decodedToken);

      localStorage.setItem("user_token", response.data.token);
      localStorage.setItem("userRecord", JSON.stringify(decodedToken.record));

      setTimeout(() => {
        if (isRestaurant) {
          navigate("/dashBoard");
          localStorage.setItem("user_id", response.data.id);
        } else {
          navigate("/myOrders");
          localStorage.setItem("restUser_id", response.data.id);
        }
      }, 1500);

      setEmail("");
      setPassword("");
    } catch (error) {
      if (error.response && error.response.data) {
        notifyError(error.response.data.message);
      } else {
        notifyError("An error occurred during login.");
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login h-100">
      <Row className="w-100 m-auto">
        <ToastContainer />
        <Col xs={12} md={12} lg={6} xl={7} className="p-0 h-100">
          <Container className="container-relative h-100">
            <img
              className="login-logo"
              src={mainLogo}
              alt="logo"
              onClick={() => navigate("/")}
            />
            <div className="side-form">
              <h1 className="mb-3">Login</h1>
              <p className="mb-4">
                Get started to access your restaurant management tools.
              </p>
              <form onSubmit={handleLogin} className="form">
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <label className="mb-2" htmlFor="email">
                      Email Address
                    </label>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      autoComplete="false"
                      autoSave="false"
                      autoFocus
                      required
                      className="input mb-3"
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter Your Email Address"
                    />
                  </Col>
                  <Col xs={12} md={12} lg={12}>
                    <label className="mb-2" htmlFor="password">
                      Password
                    </label>
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <input
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        autoComplete="false"
                        className="input mb-3"
                        type={showPassword ? "text" : "password"} // Toggle input type
                        name="password"
                        id="password"
                        placeholder="Enter Your Password"
                      />
                      <span
                        onClick={togglePasswordVisibility}
                        className="password-toggle-icon"
                        style={{
                          position: "absolute",
                          right: "1rem",
                          top: "1rem",
                          cursor: "pointer",
                        }}
                      >
                        {showPassword ? (
                          <FaEyeSlash className="eye" />
                        ) : (
                          <FaEye className="eye" />
                        )}{" "}
                        {/* Icon toggling */}
                      </span>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <div
                        className="form-check"
                        style={{
                          display: "flex",
                          alignItems: "baseline",
                          gap: "10px",
                        }}
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexCheckDefault"
                          onChange={(e) => setIsRestaurant(e.target.checked)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Sign in As a Restaurant?
                        </label>
                      </div>
                      <div
                        className="forget-link"
                        style={{ textAlign: "right" }}
                      >
                        <Link to="/forgetPassword" className="forget mb-0">
                          Forget Password?
                        </Link>
                      </div>
                    </div>
                  </Col>
                </Row>
                <button className="login-rest-btn">Login</button>
              </form>
            </div>
            <p className="login-footer">© 2024 SmartServe</p>
          </Container>
        </Col>
        <Col xs={0} md={0} lg={6} xl={5} className="p-0">
          <div className="side-login">
            <div className="side-content">
              <p>Great Opportunities Await! Log In Now to Get Started.</p>
              <img src={login_img} alt="img" />
            </div>
            <img className="audio-login" src={logo} alt="logo" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
