import React, { useEffect, useState } from "react";
import RestDashNav from "../../Components/RestDashNav/RestDashNav";
import { useNavigate } from "react-router-dom";
import TokensChartBar from "./TokensChartBar";
import { getRestAllTokensAnalysis } from "../../utilies/api";

const TokensChartPage = () => {
  const navigate = useNavigate();
  const rest_id = localStorage.getItem("user_id");
  const [tokens, setTokens] = useState([]);
  const [filter, setFilter] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const getAllTokens = async (rest_id, filter) => {
    try {
      const response = await getRestAllTokensAnalysis(rest_id, filter);
      setTokens(response?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getAllTokens(rest_id, filter);
  }, [rest_id, filter]);
  return (
    <>
      <RestDashNav
        title={"Tokens Overview"}
        subTitle={"Track your sales and export it."}
        redirectPath={"/dashboard"}
        navigate={navigate}
      />
      <div className="my-orders dashboard">
        {isLoading ? (
          <div className="spinner" aria-live="polite">
            <div className="loader" aria-label="Loading..."></div>
          </div>
        ) : tokens.length > 0 ? (
          <>
            <div className="d-flex align-items-center justify-content-end">
              <label htmlFor="filter" className="form-label me-3 mb-4 fw-bold">
                Filter By:
              </label>
              <select
                id="filter"
                onChange={(e) => setFilter(e.target.value)}
                name="filter"
                value={filter}
                className="form-select border-1 fw-bold"
                style={{ width: "fit-content" }}
                aria-label="Select time period for tokens"
              >
                <option value="" disabled>
                  Select Time Period
                </option>
                <option value="day">Day</option>
                <option value="week">Week</option>
                <option value="month">Month</option>
                <option value="year">Year</option>
              </select>
            </div>
            <TokensChartBar tokens={tokens} />
          </>
        ) : (
          <b className="text-danger fs-3 fw-bold">No Charts Available!</b>
        )}
      </div>
    </>
  );
};

export default TokensChartPage;
