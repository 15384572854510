import { Col, Container, Row } from "react-bootstrap";
import back_1 from "../../assets/hero_section/back_1.png";
import back_2 from "../../assets/hero_section/back_2.png";
import back_3 from "../../assets/hero_section/back_3.png";
import logo from "../../assets/main_logo.png";
import "./heroSection.css";
import GetInTouchSection from "../GetInTouchSection/GetInTouchSection";
// bot images
// import white_logo from "../../assets/voiceBot/white_bot_icon.svg";
// import blue_logo from "../../assets/voiceBot/blue_bot_icon.svg";
// import { useState } from "react";
// import BotModal from "./BotModal";
// import RecordBot from "./RecordBot";
// import MsgBot from "./MsgBot";

const HeroSection = () => {
  // const [modalShow, setModalShow] = useState(false);
  // const [recordModal, setRecordModal] = useState(false);
  // const [msgModal, setMsgModal] = useState(false);

  return (
    <div className="hero-section">
      <Container>
        <Row>
          <Col xs={12} md={12} lg={5}>
            <GetInTouchSection
              heading_logo={logo}
              heading_title={"SmartServe for restaurants"}
              main_title={
                "Never Miss a Call: AI Assistant Answers & Takes Orders 24/7"
              }
              main_btn={"Get in touch"}
            />
          </Col>
          <Col xs={12} md={12} lg={7}>
            <div className="gallery">
              <figure className="card-item">
                <img src={back_1} alt="Lakeview Elegance preview" />
                <span className="first">Elevate.</span>
              </figure>
              <figure className="card-item">
                <img src={back_2} alt="Skyline Oasis preview" />
                <span className="second">Engage.</span>
              </figure>
              <figure className="card-item">
                <img src={back_3} alt="Vista Paradiso preview" />
                <span className="third">Elvove.</span>
              </figure>
            </div>
          </Col>
        </Row>
      </Container>
      {/* <img
        onClick={() => setModalShow(!modalShow)}
        className="bot-logo"
        src={modalShow || recordModal || msgModal ? blue_logo : white_logo}
        alt="logo"
      />
      <BotModal
        recordModal={recordModal}
        setRecordModal={setRecordModal}
        msgModal={msgModal}
        setMsgModal={setMsgModal}
        modalShow={modalShow}
        onHide={() => setModalShow(false)}
      />
      <RecordBot modalShow={recordModal} onHide={() => setRecordModal(false)} />
      <MsgBot modalShow={msgModal} onHide={() => setMsgModal(false)} /> */}
    </div>
  );
};

export default HeroSection;
