import React, { useEffect, useState } from "react";
import RestDashNav from "../../Components/RestDashNav/RestDashNav";
import { useNavigate } from "react-router-dom";
import { getRestReveneu } from "../../utilies/api";
import ChartBar from "./ChartBar";

const RevenewChartPage = () => {
  const navigate = useNavigate();
  const rest_id = localStorage.getItem("user_id");
  const [revenue, setRevenue] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getReveneu = async (rest_id) => {
    try {
      const response = await getRestReveneu(rest_id);
      setRevenue(response?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (rest_id) {
      getReveneu(rest_id);
    }
  }, [rest_id]);
  return (
    <>
      <RestDashNav
        title={"Sales Overview"}
        subTitle={"Track your sales and export it."}
        redirectPath={"/dashboard"}
        navigate={navigate}
      />
      <div className="my-orders dashboard" style={{ height: "88vh" }}>
        {isLoading ? (
          <div className="spinner">
            <div className="loader"></div>
          </div>
        ) : revenue.length > 0 ? (
          <ChartBar revenue={revenue} />
        ) : (
          <b className="text-danger fs-3 fw-bold">No Charts Available!</b>
        )}
      </div>
    </>
  );
};

export default RevenewChartPage;
