import axios from "axios";

export const base_url = "https://wizard.smartserve.ai"; // for local

const token = localStorage.getItem("speech_token");
const Rest_token = localStorage.getItem("user_token");
// restaurant request to join
export const requestToJoin = (formData) => {
  let data = axios.post(`${base_url}/restaurant/request`, formData, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return data;
};

// restaurant signin api
export const userLogin = (formData) => {
  let data = axios.post(`${base_url}/restaurant/auth/login`, formData, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return data;
};

// restaurant reset password api
export const userResetPassword = (formData) => {
  let data = axios.post(`${base_url}/restaurant/auth/reset`, formData, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return data;
};

// restaurant reset password api
export const userUpdatePassword = (formData) => {
  let data = axios.put(`${base_url}/restaurant/auth/password`, formData, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return data;
};

// speech to text
export const speechResetPassword = (formData) => {
  let data = axios.post(`${base_url}/stt/auth/reset`, formData, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return data;
};
// restaurant reset password api
export const speechUpdatePassword = (formData) => {
  let data = axios.put(`${base_url}/stt/auth/password`, formData, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return data;
};
// get company details in stt profile
export const getCompanyData = async (companyId) => {
  const { data } = await axios.get(
    `${base_url}/stt/auth?companyId=${companyId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};
// update stt company profile data
export const updateCompanyData = async (formData) => {
  const { data } = await axios.put(`${base_url}/stt/auth`, formData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};
// upload stt company profile photo
export const uploadCompanyPhoto = async (imgEndPoint, formData, token) => {
  const { data } = await axios.put(
    `${base_url}/${imgEndPoint}/image`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};
// ---------------------------------------------------------
// Restaurant APIs
// get all categories
export const getCategories = async (rest_token, menu_id) => {
  const { data } = await axios.get(`${base_url}/menu/category`, {
    params: {
      menuId: menu_id,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${rest_token}`,
    },
  });
  return data;
};
// add new category
export const addCategory = async (formData, token) => {
  const { data } = await axios.post(`${base_url}/menu/category`, formData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};
// edit category
export const editCategory = async (formData, token) => {
  const { data } = await axios.put(`${base_url}/menu/category`, formData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};
// delete category
export const deleteRestaurantCategory = async (cat_id, token) => {
  const { data } = await axios.delete(
    `${base_url}/menu/category?id=${cat_id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};
// add new item
export const addItem = async (formData, token) => {
  const { data } = await axios.post(`${base_url}/menu`, formData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};
export const uploadItemPhoto = async (imgEndPoint, formData, token) => {
  const { data } = await axios.post(
    `${base_url}/${imgEndPoint}/image`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};
// edit item
export const editItem = async (formData) => {
  const { data } = await axios.put(`${base_url}/menu`, formData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Rest_token}`,
    },
  });
  return data;
};
// delete item
export const deleteRestaurantItem = async (item_id, token) => {
  const { data } = await axios.delete(`${base_url}/menu?id=${item_id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};
// get specific item details
export const getItemDetails = async (itemId, menuId, token) => {
  const { data } = await axios.get(
    `${base_url}/menu?menuId=${menuId}&id=${itemId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};
// get specific category details
export const getCategoryDetails = async (categoryid, menuId, token) => {
  const { data } = await axios.get(`${base_url}/menu/category`, {
    params: { id: categoryid, menuId: menuId },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};
// update restaurant data
export const updateRestaurantData = async (formData, token) => {
  const { data } = await axios.put(`${base_url}/restaurant/user`, formData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};
// get all restaurant users
export const GetRestaurantUsers = async (
  id,
  name,
  phone,
  status,
  level,
  token,
  page
) => {
  const { data } = await axios.get(`${base_url}/restaurant/user`, {
    params: {
      restaurantId: id,
      name: name,
      phone: phone,
      status: status,
      level: level,
      page: page,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};
// add new restaurant user
export const addRestaurantUser = async (formData, token) => {
  const { data } = await axios.post(`${base_url}/restaurant/user`, formData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};
export const deleteRestaurantUser = async (user_id, token) => {
  const { data } = await axios.delete(
    `${base_url}/restaurant/user?id=${user_id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};
// get all menu items
export const GetRestaurantMenuItems = async (
  itemName,
  menuId,
  itemAvailability,
  categoryId,
  token,
  page
) => {
  const { data } = await axios.get(`${base_url}/menu`, {
    params: {
      name: itemName,
      menuId: menuId,
      availability: itemAvailability,
      categoryId: categoryId,
      page: page,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};
// get all categories items
export const GetRestaurantCategories = async (
  restId,
  catName,
  catNum,
  token
) => {
  const { data } = await axios.get(`${base_url}/menu/category`, {
    params: {
      menuId: restId,
      name: catName,
      number: catNum,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};
// get restaurant data
export const GetRestaurantDetails = async (id, token) => {
  const { data } = await axios.get(`${base_url}/restaurant/active`, {
    params: {
      id: id,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};
// update restaurant admin data
export const updateRestData = async (formData) => {
  const { data } = await axios.put(`${base_url}/restaurant/active`, formData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Rest_token}`,
    },
  });
  return data;
};
// get restaurant user data
export const GetRestaurantUserDetails = async (user_id) => {
  const { data } = await axios.get(
    `${base_url}/restaurant/user?id=${user_id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Rest_token}`,
      },
    }
  );
  return data;
};
// update restaurant user data
export const updateRestUserData = async (formData) => {
  const { data } = await axios.put(`${base_url}/restaurant/user`, formData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Rest_token}`,
    },
  });
  return data;
};
// get restaurant orders data
export const GetRestaurantOrdersDetails = async (
  id,
  status,
  name,
  orderMethod,
  orderDate,
  orderType,
  rest_id,
  page
) => {
  const { data } = await axios.get(`${base_url}/order`, {
    params: {
      id: id,
      status: status,
      name: name,
      paymentMethod: orderMethod,
      createdAt: orderDate,
      orderType: orderType,
      restaurantId: rest_id,
      page: page,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Rest_token}`,
    },
  });
  return data;
};
// update order status
export const updateRestaurantOrderStatus = async (formData) => {
  const { data } = await axios.put(`${base_url}/order`, formData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Rest_token}`,
    },
  });
  return data;
};
// dashboard main page recent orders
export const getRecentOrders = async (rest_id) => {
  const { data } = await axios.get(
    `${base_url}/order?sort=createdAt&restaurantId=${rest_id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Rest_token}`,
      },
    }
  );
  return data;
};
// dashboard main page recent users
export const getRecentUsers = async (rest_id) => {
  const { data } = await axios.get(
    `${base_url}/restaurant/user?sort=createdAt&restaurantId=${rest_id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Rest_token}`,
      },
    }
  );
  return data;
};
export const getRestSlaes = async (rest_id) => {
  const { data } = await axios.get(
    `${base_url}/restaurant/dashboard/totalSales?id=${rest_id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Rest_token}`,
      },
    }
  );
  return data;
};
export const getRestTokens = async (rest_id) => {
  const { data } = await axios.get(
    `${base_url}/restaurant/dashboard/total-tokens?id=${rest_id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Rest_token}`,
      },
    }
  );
  return data;
};
// get fav items
export const getFavItems = async (rest_id) => {
  const { data } = await axios.get(
    `${base_url}/restaurant/dashboard/favItems?id=${rest_id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Rest_token}`,
      },
    }
  );
  return data;
};

export const getRestReveneu = async (rest_id) => {
  const { data } = await axios.get(
    `${base_url}/restaurant/dashboard/sales-over-week?id=${rest_id}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

export const getRestAllTokens = async (rest_id) => {
  const { data } = await axios.get(
    `${base_url}/restaurant/dashboard/tokens-over-week?id=${rest_id}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

export const getRestAllTokensAnalysis = async (rest_id, filter) => {
  const { data } = await axios.get(`${base_url}/restaurant/dashboard/tokens`, {
    params: {
      id: rest_id,
      filter: filter,
    },
    headers: {
      "Content-Type": "application/json",
    },
  });
  return data;
};
