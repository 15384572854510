import React, { useEffect, useState } from "react";
import RestDashNav from "../../RestDashNav/RestDashNav";
import { Col, Row } from "react-bootstrap";
import DashBoardFooter from "../../DashBoardFooter/DashBoardFooter";
import "./speechDashbaord.css";
import rect_1 from "../../../assets/speechToText/dashboard/rect_1.png";
import rect_2 from "../../../assets/speechToText/dashboard/rect_2.png";
import rect_3 from "../../../assets/speechToText/dashboard/rect_3.png";
import axios from "axios";
import { base_url, getCompanyData } from "../../../utilies/api";
import PieChart from "./PieChart";

const SpeechDashbaord = () => {
  // get company data
  const userObject = JSON.parse(localStorage.getItem("userRecord"));
  const [companyData, setCompanyData] = useState([]);
  const [subscriptionId, setSubscriptionId] = useState("");
  const getCompanyDataFun = async (company_id) => {
    const response = await getCompanyData(company_id);
    setCompanyData(response?.data[0]);
    setSubscriptionId(response?.data[0]?.Subscription?.id);
  };

  const getFilesType = async () => {
    const response = await axios.get(`${base_url}/stt/audiofile`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("speech_token")}`,
        // Add more headers as needed
      },
    });
    setFilesType(response.data.data);
  };
  // get files type
  const [filesType, setFilesType] = useState([]);
  const fileArr =
    (filesType.filter((item) => item.type === "file").length /
      filesType.length) *
    100;

  const linkArr =
    (filesType.filter((item) => item.type === "link").length /
      filesType.length) *
    100;

  const audioArr =
    (filesType.filter((item) => item.type === "record").length /
      filesType.length) *
    100;

  // get company billing
  const [companyBilling, setCompanyBilling] = useState([]);

  useEffect(() => {
    getCompanyDataFun(userObject.company_Id);
    const getBilling = async (subscriptionId) => {
      if (companyData?.Subscription?.tier !== "demo") {
        const response = await axios.get(
          `${base_url}/stt/billing?subscriptionId=${subscriptionId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("speech_token")}`,
            },
          }
        );
        setCompanyBilling(response.data.data);
      }
    };
    if (subscriptionId) {
      getBilling(subscriptionId);
    }
    getFilesType();
  }, [subscriptionId, userObject.company_Id, companyData?.Subscription?.tier]);
  return (
    <>
      <RestDashNav
        title={"Dashboard"}
        subTitle={"Convert spoken words or audio content into written text."}
        redirectPath={"/speech/signin"}
        companyData={companyData}
      />
      <div className="my-orders">
        <div className="speech-dash">
          <Row>
            <Col xs={12} md={12} lg={5} xl={4}>
              <div
                className="charts-sales"
                style={{ flexDirection: "column", justifyContent: "center" }}
              >
                <h1>
                  Welcome, <span>{companyData.name}</span>
                </h1>
                <p className="mb-3">
                  You are currently on{" "}
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#f4a223",
                      textTransform: "capitalize",
                    }}
                  >
                    {companyData?.Subscription?.tier} Subscription
                  </span>
                </p>
                <div className="date mb-3">
                  <span>Start Date</span>
                  <span style={{ color: "#007550" }}>
                    {companyData?.Subscription?.startDate
                      ?.replace("T", " ")
                      ?.slice(0, 19)}
                  </span>
                </div>
                <div className="date">
                  <span>End Date</span>
                  <span style={{ color: "#F15623" }}>
                    {companyData?.Subscription?.endDate
                      ?.replace("T", " ")
                      ?.slice(0, 19)}
                  </span>
                </div>
              </div>
            </Col>
            <Col xs={12} md={12} lg={7} xl={5}>
              <div className="charts-sales special-charts">
                {filesType.length > 0 ? (
                  <PieChart
                    fileArr={fileArr}
                    audioArr={audioArr}
                    linkArr={linkArr}
                  />
                ) : (
                  <span className="no-charts">No Charts Available</span>
                )}
                <div className="charts-info">
                  <div className="chart-info mb-2">
                    <div className="chart-rect">
                      <img src={rect_1} alt="revtangle" />
                      <span>Files Transcription </span>
                    </div>
                    <span>
                      {filesType.length > 0
                        ? parseFloat(fileArr).toFixed(1)
                        : 0}
                      %
                    </span>
                  </div>
                  <div className="chart-info mb-2">
                    <div className="chart-rect">
                      <img src={rect_2} alt="revtangle" />
                      <span>Link Transcription </span>
                    </div>
                    <span>
                      {filesType.length > 0
                        ? parseFloat(linkArr).toFixed(1)
                        : 0}
                      %
                    </span>
                  </div>
                  <div className="chart-info">
                    <div className="chart-rect">
                      <img src={rect_3} alt="revtangle" />
                      <span>Audio Recording Transcription </span>
                    </div>
                    <span>
                      {filesType.length > 0
                        ? parseFloat(audioArr).toFixed(1)
                        : 0}
                      %
                    </span>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} md={12} lg={12} xl={3}>
              <div
                className="charts-sales"
                style={{
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <h1 className="mb-3">Transcription Time</h1>
                <div className="date mb-3">
                  <span>Total Time </span>
                  <span style={{ fontSize: "14px" }}>
                    {companyBilling.length > 0
                      ? parseFloat(
                          companyBilling[0]?.Subscription?.numberOfMinutes
                        ).toFixed(3)
                      : parseFloat(
                          companyData?.Subscription?.numberOfMinutes
                        ).toFixed(3)}{" "}
                    Min
                  </span>
                </div>
                <div className="date mb-3">
                  <span>Total Amount</span>
                  <span style={{ fontSize: "14px", color: "#F15623" }}>
                    {companyData?.Subscription?.tier !== "demo"
                      ? `${parseFloat(companyBilling[0]?.totalCost).toFixed(
                          3
                        )} $`
                      : "Free"}{" "}
                  </span>
                </div>
                <div className="date">
                  <span style={{ visibility: "hidden" }}>Used Time</span>
                  <span style={{ color: "#F15623", visibility: "hidden" }}>
                    1 Hr
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <DashBoardFooter />
      </div>
    </>
  );
};

export default SpeechDashbaord;
