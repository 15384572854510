import React, { useState } from "react";
import { Col } from "react-bootstrap";
import audio from "../../assets/speechToText/speechAudio/mdi_microphone.png";
import filled_audio from "../../assets/speechToText/speechAudio/fluent_mic-record-24-filled.png";
import "./record.css";

const RecordBox = ({ setAudioFile }) => {
  const [mediaStream, setMediaStream] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recording, setRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  console.log(audioBlob);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);

      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          setAudioBlob(event.data);
          setAudioFile({
            audio: event.data,
            type: "record",
          }); // Pass audio blob to parent component
        }
      };

      recorder.start();
      setMediaStream(stream);
      setMediaRecorder(recorder);
      setRecording(true);
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      mediaStream.getTracks().forEach((track) => track.stop()); // Stop all tracks
      setRecording(false);
    }
  };

  return (
    <Col xs={12} md={6} lg={4}>
      <div className="audio-box">
        <img src={recording ? filled_audio : audio} alt="audio" />
        <p>{recording ? "Recording Now" : "Record from microphone"}</p>
        {recording ? (
          <button
            style={{ backgroundColor: "#F15623" }}
            onClick={stopRecording}
          >
            Stop Recording
          </button>
        ) : (
          <button style={{ backgroundColor: "black" }} onClick={startRecording}>
            Start Recording
          </button>
        )}
      </div>
    </Col>
  );
};

export default RecordBox;
